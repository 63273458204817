export const enums = {
  LAPSED: -1,
  CANCELLED: 0,
  ACTIVE: 1,
  OVERDUE: 2
};

export const statuses = {
  LAPSED: {
    label: "Lapsed",
    value: -1
  },
  CANCELLED: {
    label: "Cancelled",
    value: 0
  },
  ACTIVE: {
    label: "Active",
    value: 1
  },
  OVERDUE: {
    label: "Overdue",
    value: 2
  }
};
